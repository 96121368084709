import React from 'react';
import { Link } from 'react-router-dom';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  FormGroup,
  Input,
  Button,
  Label,
  Table,
  UncontrolledTooltip,
} from 'reactstrap';

import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from 'react-tabs';

import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

import TextareaAutosize from 'react-textarea-autosize';

import { connect } from 'react-redux';

import moment from 'moment';
import Loader from 'react-loader-spinner';
import Select from 'react-select';
import NumberFormat from 'react-number-format';
import validator from 'validator';

import Alert from 'views/components/Alert';
import AdaptiveTable from 'views/components/AdaptiveTable';
import DatePicker from 'views/components/DatePicker';
import ConfirmModal from 'views/components/ConfirmModal';
import LoaderModal from 'views/components/LoaderModal';
import ListagemChamados from 'views/components/ListagemChamados';

import SituacaoEnte from 'assets/csv/situacoes-entes.json';
import TipoPlano from 'assets/csv/tipos-plano.json';
import Papel from 'assets/csv/papeis.json';
import SituacaoChamado from 'assets/csv/situacoes-chamado.json';

import LoginService from 'services/LoginService';
import EntesService from 'services/EntesService';
import ConvitesService from 'services/ConvitesService';
import PlanosService from 'services/PlanosService';
import MChatsService from 'services/MChatsService';

import getListaMChatPadrao from 'utils/getListaMChatPadrao';

class LimitesEnte extends React.Component {
  constructor(props) {
    super(props);

    this.situacoes = [
      SituacaoEnte.ATIVO,
      SituacaoEnte.BLOQUEADO,
    ];

    this.tiposPlano = [
      TipoPlano.K10,
      TipoPlano.K20,
      TipoPlano.K50,
      TipoPlano.CUSTOM,
    ];

    this.state = {
      situacaoState: '',
      situacaoSelecionada: '',

      planoSelecionado: '',
      planoState: '',

      viewDate: new Date(),
    };

    this.entesService = new EntesService();
    this.planosService = new PlanosService();
  }

  async componentDidMount() {
    try {
      const ente = await this.entesService
        .carregarEnte(this.props.idEnte);

      const dadosEnte = await this.entesService
        .carregarDadosEntePorIdEnte(this.props.idEnte);

      const limites = await this.entesService
        .carregarLimites(this.props.idEnte);

      const uso = await this.entesService
        .carregarUso(this.props.idEnte);

      const planos = await this.planosService
        .carregarPlanos();

      planos.forEach((plano) => {
        Object.assign(plano, {
          value: plano.tipo,
          label: this.tiposPlano[plano.tipo].label,
        });
      });

      this.setState({
        ente,
        dadosEnte,
        limites,
        uso,
        planos,
        planoSelecionado: planos[dadosEnte.tipoPlano],
        dataVencimentoPlano: dadosEnte.dataVencimentoPlano,
        situacaoSelecionada: this.situacoes[ente.situacao],
        viewDate: dadosEnte.dataVencimentoPlano
          ? moment(dadosEnte.dataVencimentoPlano, 'DD/MM/YYYY').toDate()
          : new Date(),
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  validarCampos() {
    let ret = true;
    if (!this.state.situacaoSelecionada) {
      this.setState({ situacaoState: 'danger' });
      ret = false;
    }
    if (!this.state.planoSelecionado) {
      this.setState({ planoState: 'danger', planoStateMsg: 'Informe o plano do ente.' });
      ret = false;
    }
    if (this.state.limites.escolas === '') {
      this.setState({ escolasState: 'has-danger' });
      ret = false;
    }
    if (this.state.limites.cursos === '') {
      this.setState({ cursosState: 'has-danger' });
      ret = false;
    }
    if (this.state.limites.estruturasCurriculares === '') {
      this.setState({ estruturasCurricularesState: 'has-danger' });
      ret = false;
    }
    if (this.state.limites.anosLetivos === '') {
      this.setState({ anosLetivosState: 'has-danger' });
      ret = false;
    }
    if (this.state.limites.turmas === '') {
      this.setState({ turmasState: 'has-danger' });
      ret = false;
    }
    if (this.state.limites.convites === '') {
      this.setState({ convitesState: 'has-danger' });
      ret = false;
    }
    if (this.state.limites.diretores === '') {
      this.setState({ diretoresState: 'has-danger' });
      ret = false;
    }
    if (this.state.limites.professores === '') {
      this.setState({ professoresState: 'has-danger' });
      ret = false;
    }
    if (this.state.limites.alunos === '') {
      this.setState({ alunosState: 'has-danger' });
      ret = false;
    }
    if (this.state.limites.historicos === '') {
      this.setState({ historicosState: 'has-danger' });
      ret = false;
    }
    if (!this.state.dataVencimentoPlano) {
      this.setState({ dataVencimentoPlanoState: 'has-danger' });
      ret = false;
    }
    return ret;
  }

  async salvarConfiguracoesEnte() {
    if (!this.validarCampos()) return;

    this.props.callbackMessage({
      showLoaderModal: true,
      showAlert: false,
      loaderModalText: 'Salvando informações de limites...',
    });

    const configuracoesEnte = {
      idEnte: this.state.ente.id,
      situacao: this.state.situacaoSelecionada.value,
      tipoPlano: this.state.planoSelecionado.value,
      limites: this.state.limites,
      dataVencimentoPlano: this.state.dataVencimentoPlano,
    };

    try {
      await this.entesService.salvarConfiguracoesEnte(configuracoesEnte);

      this.props.callbackMessage({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Configurações de limites salvas com sucesso',
      });

      this.setState({
        planoState: '',
        dataVencimentoPlanoState: '',
      });
    } catch (e) {
      this.props.callbackMessage({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao salvar configurações de limites',
      });
    }
  }

  render() {
    if (this.state.erro) return <div align="center" style={{ margin: 50 }}>
      Erro ao buscar informações de limites
    </div>

    return this.state.ente ?
      <>
        <Row>
          <Col md="3">
            <FormGroup className={'has-label has-danger'}>
              <label>Situação *</label>
              <Select
                noOptionsMessage={() => 'Nenhuma entrada'}
                className={`react-select primary ${this.state.situacaoState}`}
                classNamePrefix="react-select"
                value={this.state.situacaoSelecionada}
                onChange={(event) => {
                  this.setState({ situacaoState: '', situacaoSelecionada: event });
                }}
                options={this.situacoes}
                placeholder="Situação ente..."
              />
              {this.state.situacaoState === 'danger' ? (
                <label className="error">
                  Informe a situação do ente.
                </label>
              ) : null}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="3">
            <FormGroup className={'has-label has-danger'}>
              <label>Plano *</label>
              <Select
                noOptionsMessage={() => 'Nenhuma entrada'}
                className={`react-select primary ${this.state.planoState}`}
                classNamePrefix="react-select"
                value={this.state.planoSelecionado}
                onChange={(event) => {
                  this.setState({
                    planoState: '',
                    planoSelecionado: event,
                    dataVencimentoPlano: moment().add(1, 'Y').add(1, 'd').format('DD/MM/YYYY'),
                  });
                }}
                options={this.state.planos}
                placeholder="Plano ente..."
              />
              {this.state.planoState === 'danger' ? (
                <label className="error">
                  {this.state.planoStateMsg}
                </label>
              ) : null}
            </FormGroup>
          </Col>
          <Col md="2">
            <FormGroup className={`has-label ${this.state.dataVencimentoPlanoState}`}>
              <label>Data de vencimento</label>
              <DatePicker
                viewDate={this.state.viewDate}
                value={this.state.dataVencimentoPlano}
                clearButtonCallback={() => this.setState({
                  dataVencimentoPlano: undefined,
                })}
                onChange={(data) => {
                  const dataStr = moment(data).format('DD/MM/YYYY');
                  this.setState({
                    viewDate: data.toDate ? data.toDate() : this.state.viewDate,
                    dataVencimentoPlano: dataStr,
                    dataVencimentoPlanoState: '',
                  });
                }}
              />
              {this.state.dataVencimentoPlanoState === 'has-danger' ? (
                <label className="error">
                  Informe a data de vencimento do plano
                </label>
              ) : null}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="1">
            <FormGroup className={`has-label ${this.state.escolasState}`}>
              <label>Escolas *</label>
              <Input
                value={this.state.limites.escolas}
                decimalSeparator={false}
                allowNegative={false}
                maxLength={6}
                style={{ textAlign: 'right' }}
                onValueChange={(val) => {
                  this.setState({
                    limites: { ...this.state.limites, escolas: val.formattedValue },
                    escolasState: '',
                  });
                }}
                tag={NumberFormat} />
              {this.state.escolasState === 'has-danger' ? (
                <label className="error">
                  Informe o limite de escolas.
                </label>
              ) : null}
            </FormGroup>
          </Col>
          <Col md="1">
            <FormGroup className={`has-label ${this.state.cursosState}`}>
              <label>Cursos *</label>
              <Input
                value={this.state.limites.cursos}
                decimalSeparator={false}
                allowNegative={false}
                maxLength={6}
                style={{ textAlign: 'right' }}
                onValueChange={(val) => {
                  this.setState({
                    limites: { ...this.state.limites, cursos: val.formattedValue },
                    cursosState: '',
                  });
                }}
                tag={NumberFormat} />
              {this.state.cursosState === 'has-danger' ? (
                <label className="error">
                  Informe o limite de cursos.
                </label>
              ) : null}
            </FormGroup>
          </Col>
          <Col md="1">
            <FormGroup className={`has-label ${this.state.estruturasCurricularesState}`}>
              <label>E. Curriculares *</label>
              <Input
                value={this.state.limites.estruturasCurriculares}
                decimalSeparator={false}
                allowNegative={false}
                maxLength={6}
                style={{ textAlign: 'right' }}
                onValueChange={(val) => {
                  this.setState({
                    limites: {
                      ...this.state.limites,
                      estruturasCurriculares: val.formattedValue,
                    },
                    estruturasCurricularesState: '',
                  });
                }}
                tag={NumberFormat} />
              {this.state.estruturasCurricularesState === 'has-danger' ? (
                <label className="error">
                  Informe o limite de estruturas curriculares.
                </label>
              ) : null}
            </FormGroup>
          </Col>
          <Col md="1">
            <FormGroup className={`has-label ${this.state.anosLetivosState}`}>
              <label>Anos Letivos *</label>
              <Input
                value={this.state.limites.anosLetivos}
                decimalSeparator={false}
                allowNegative={false}
                maxLength={6}
                style={{ textAlign: 'right' }}
                onValueChange={(val) => {
                  this.setState({
                    limites: {
                      ...this.state.limites,
                      anosLetivos: val.formattedValue,
                    },
                    anosLetivosState: '',
                  });
                }}
                tag={NumberFormat} />
              {this.state.anosLetivosState === 'has-danger' ? (
                <label className="error">
                  Informe o limite de anos letivos.
                </label>
              ) : null}
            </FormGroup>
          </Col>
          <Col md="1">
            <FormGroup className={`has-label ${this.state.turmasState}`}>
              <label>Turmas *</label>
              <Input
                value={this.state.limites.turmas}
                decimalSeparator={false}
                allowNegative={false}
                maxLength={6}
                style={{ textAlign: 'right' }}
                onValueChange={(val) => {
                  this.setState({
                    limites: { ...this.state.limites, turmas: val.formattedValue },
                    turmasState: '',
                  });
                }}
                tag={NumberFormat} />
              {this.state.turmasState === 'has-danger' ? (
                <label className="error">
                  Informe o limite de turmas.
                </label>
              ) : null}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="1">
            <FormGroup className={`has-label ${this.state.convitesState}`}>
              <label>Convites *</label>
              <Input
                value={this.state.limites.convites}
                decimalSeparator={false}
                allowNegative={false}
                maxLength={6}
                style={{ textAlign: 'right' }}
                onValueChange={(val) => {
                  this.setState({
                    limites: { ...this.state.limites, convites: val.formattedValue },
                    convitesState: '',
                  });
                }}
                tag={NumberFormat} />
              {this.state.convitesState === 'has-danger' ? (
                <label className="error">
                  Informe o limite de convites.
                </label>
              ) : null}
            </FormGroup>
          </Col>
          <Col md="1">
            <FormGroup className={`has-label ${this.state.diretoresState}`}>
              <label>Diretores *</label>
              <Input
                value={this.state.limites.diretores}
                decimalSeparator={false}
                allowNegative={false}
                maxLength={6}
                style={{ textAlign: 'right' }}
                onValueChange={(val) => {
                  this.setState({
                    limites: { ...this.state.limites, diretores: val.formattedValue },
                    diretoresState: '',
                  });
                }}
                tag={NumberFormat} />
              {this.state.diretoresState === 'has-danger' ? (
                <label className="error">
                  Informe o limite de diretores.
                </label>
              ) : null}
            </FormGroup>
          </Col>
          <Col md="1">
            <FormGroup className={`has-label ${this.state.professoresState}`}>
              <label>Professores *</label>
              <Input
                value={this.state.limites.professores}
                decimalSeparator={false}
                allowNegative={false}
                maxLength={6}
                style={{ textAlign: 'right' }}
                onValueChange={(val) => {
                  this.setState({
                    limites: {
                      ...this.state.limites,
                      professores: val.formattedValue,
                    },
                    professoresState: '',
                  });
                }}
                tag={NumberFormat} />
              {this.state.professoresState === 'has-danger' ? (
                <label className="error">
                  Informe o limite de professores.
                </label>
              ) : null}
            </FormGroup>
          </Col>
          <Col md="1">
            <FormGroup className={`has-label ${this.state.alunosState}`}>
              <label>Alunos *</label>
              <Input
                value={this.state.limites.alunos}
                decimalSeparator={false}
                allowNegative={false}
                maxLength={6}
                style={{ textAlign: 'right' }}
                onValueChange={(val) => {
                  this.setState({
                    limites: { ...this.state.limites, alunos: val.formattedValue },
                    alunosState: '',
                  });
                }}
                tag={NumberFormat} />
              {this.state.alunosState === 'has-danger' ? (
                <label className="error">
                  Informe o limite de alunos.
                </label>
              ) : null}
            </FormGroup>
          </Col>
          <Col md="1">
            <FormGroup className={`has-label ${this.state.historicosState}`}>
              <label>Históricos *</label>
              <Input
                value={this.state.limites.historicos}
                decimalSeparator={false}
                allowNegative={false}
                maxLength={6}
                style={{ textAlign: 'right' }}
                onValueChange={(val) => {
                  this.setState({
                    limites: {
                      ...this.state.limites,
                      historicos: val.formattedValue,
                    },
                    historicosState: '',
                  });
                }}
                tag={NumberFormat} />
              {this.state.historicosState === 'has-danger' ? (
                <label className="error">
                  Informe o limite de históricos.
                </label>
              ) : null}
            </FormGroup>
          </Col>
        </Row>
        <h4>Em uso</h4>
        <Row>
          <Col md="1">
            <FormGroup className={`has-label ${this.state.escolasState}`}>
              <label>Escolas</label>
              <Input
                disabled
                value={this.state.uso.escolas}
                type="text"
                style={{ textAlign: 'right' }} />
            </FormGroup>
          </Col>
          <Col md="1">
            <FormGroup className={`has-label ${this.state.cursosState}`}>
              <label>Cursos</label>
              <Input
                disabled
                value={this.state.uso.cursos}
                type="text"
                style={{ textAlign: 'right' }} />
            </FormGroup>
          </Col>
          <Col md="1">
            <FormGroup className={`has-label ${this.state.estruturasCurricularesState}`}>
              <label>E. Curriculares</label>
              <Input
                disabled
                value={this.state.uso.estruturasCurriculares}
                type="text"
                style={{ textAlign: 'right' }} />
            </FormGroup>
          </Col>
          <Col md="1">
            <FormGroup className={`has-label ${this.state.anosLetivosState}`}>
              <label>Anos Letivos</label>
              <Input
                disabled
                value={this.state.uso.anosLetivos}
                type="text"
                style={{ textAlign: 'right' }} />
            </FormGroup>
          </Col>
          <Col md="1">
            <FormGroup className={`has-label ${this.state.turmasState}`}>
              <label>Turmas</label>
              <Input
                disabled
                value={this.state.uso.turmas}
                type="text"
                style={{ textAlign: 'right' }} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="1">
            <FormGroup className={`has-label ${this.state.convitesState}`}>
              <label>Convites</label>
              <Input
                disabled
                value={this.state.uso.convites}
                type="text"
                style={{ textAlign: 'right' }} />
            </FormGroup>
          </Col>
          <Col md="1">
            <FormGroup className={`has-label ${this.state.diretoresState}`}>
              <label>Diretores</label>
              <Input
                disabled
                value={this.state.uso.diretores}
                type="text"
                style={{ textAlign: 'right' }} />
            </FormGroup>
          </Col>
          <Col md="1">
            <FormGroup className={`has-label ${this.state.professoresState}`}>
              <label>Professores</label>
              <Input
                disabled
                value={this.state.uso.professores}
                type="text"
                style={{ textAlign: 'right' }} />
            </FormGroup>
          </Col>
          <Col md="1">
            <FormGroup className={`has-label ${this.state.alunosState}`}>
              <label>Alunos</label>
              <Input
                disabled
                value={this.state.uso.alunos}
                type="text"
                style={{ textAlign: 'right' }} />
            </FormGroup>
          </Col>
          <Col md="1">
            <FormGroup className={`has-label ${this.state.historicosState}`}>
              <label>Históricos *</label>
              <Input
                disabled
                value={this.state.uso.historicos}
                type="text"
                style={{ textAlign: 'right' }} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col className="text-left">
            <Button color="primary" disabled={this.state.buttonDisabled} onClick={() => this.props.history.goBack()} >
              Voltar
            </Button>
          </Col>
          <Col className="text-right">
            <Button color="primary" disabled={this.state.buttonDisabled} onClick={() => this.salvarConfiguracoesEnte()} >
              Salvar
            </Button>
          </Col>
        </Row>
      </> : <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#053d7c"
          height="50"
          width="50" />
      </div>
  }
}

class RecursosEnte extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.entesService = new EntesService();
  }

  async componentDidMount() {
    try {
      const dadosEnte = await this.entesService
        .carregarDadosEntePorIdEnte(this.props.idEnte);

      this.setState({
        dadosEnte,
        acessoAlunos: dadosEnte.acessoAlunos,
        acessoPais: dadosEnte.acessoPais,
        turmaVirtual: dadosEnte.turmaVirtual,
        alunosPorEscola: dadosEnte.alunosPorEscola,
        registroAulas: dadosEnte.registroAulas,
        habilidadesRegistroAula: dadosEnte.habilidadesRegistroAula,
        registroAulasPeriodo: dadosEnte.registroAulasPeriodo,
        recuperacaoExercicios: dadosEnte.recuperacaoExercicios,
        educaCenso: dadosEnte.educaCenso,
        competenciasMunicipais: dadosEnte.competenciasMunicipais,
        matriculasOnline: dadosEnte.matriculasOnline,
        bnccNacional: dadosEnte.bnccNacional,
        frequenciaFixa: dadosEnte.frequenciaFixa,
        frequenciaMensal: dadosEnte.frequenciaMensal,
        limitesBimestrais: dadosEnte.limitesBimestrais,
        avaliacaoEspecial: dadosEnte.avaliacaoEspecial,
        importacaoAlunos: dadosEnte.importacaoAlunos,
        somatorioExercicios: dadosEnte.somatorioExercicios,
        avaliacaoConceitual: dadosEnte.avaliacaoConceitual,
        competenciasAluno: dadosEnte.competenciasAluno,
        competenciasTurma: dadosEnte.competenciasTurma,
        relatoriosCoordenadores: dadosEnte.relatoriosCoordenadores,
        bloquearAlteracaoDiarioProfessorAuxiliar: dadosEnte.bloquearAlteracaoDiarioProfessorAuxiliar,
        controleEstoque: dadosEnte.controleEstoque,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async salvarConfiguracaoRecursos() {

    this.props.callbackMessage({ showLoaderModal: true, showAlert: false });

    try {
      const dadosEnte = {
        ente: { id: this.props.idEnte },
        acessoAlunos: this.state.acessoAlunos,
        acessoPais: this.state.acessoPais,
        turmaVirtual: this.state.turmaVirtual,
        alunosPorEscola: this.state.alunosPorEscola,
        registroAulas: this.state.registroAulas,
        habilidadesRegistroAula: this.state.habilidadesRegistroAula,
        registroAulasPeriodo: this.state.registroAulasPeriodo,
        recuperacaoExercicios: this.state.recuperacaoExercicios,
        educaCenso: this.state.educaCenso,
        competenciasMunicipais: this.state.competenciasMunicipais,
        matriculasOnline: this.state.matriculasOnline,
        bnccNacional: this.state.bnccNacional,
        frequenciaFixa: this.state.frequenciaFixa,
        frequenciaMensal: this.state.frequenciaMensal,
        limitesBimestrais: this.state.limitesBimestrais,
        avaliacaoEspecial: this.state.avaliacaoEspecial,
        importacaoAlunos: this.state.importacaoAlunos,
        somatorioExercicios: this.state.somatorioExercicios,
        avaliacaoConceitual: this.state.avaliacaoConceitual,
        competenciasAluno: this.state.competenciasAluno,
        competenciasTurma: this.state.competenciasTurma,
        relatoriosCoordenadores: this.state.relatoriosCoordenadores,
        bloquearAlteracaoDiarioProfessorAuxiliar: this.state.bloquearAlteracaoDiarioProfessorAuxiliar,
        controleEstoque: this.state.controleEstoque,
      };

      await this.entesService.salvarConfiguracaoRecursos(dadosEnte);

      this.props.callbackMessage({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Configuração de recursos salva com sucesso.',
      });
    } catch (msg) {
      if (this.umounted) return;
      this.props.callbackMessage({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao salvar configuração de recursos.',
      });
    }
  }

  render() {
    if (this.state.erro) return <div align="center" style={{ margin: 50 }}>
      Erro ao buscar informações de recursos
    </div>

    return this.state.dadosEnte ? <>
      <Row>
        <Col md="3">
          <Row>
            <Col>
              <FormGroup check>
                <Label id="acessoAlunos" className="form-check-label">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    checked={this.state.acessoAlunos}
                    onChange={(e) => this.setState({
                      acessoAlunos: e.target.checked,
                    })} />
                  Acesso alunos
                  <span className="form-check-sign">
                    <span className="check"></span>
                  </span>
                </Label>
              </FormGroup>
              <label></label>
              <UncontrolledTooltip placement="bottom" target="acessoAlunos" delay={0}>
                Habilita o login dos alunos
              </UncontrolledTooltip>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup check>
                <Label id="acessoPais" className="form-check-label">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    checked={this.state.acessoPais}
                    onChange={(e) => this.setState({
                      acessoPais: e.target.checked,
                    })} />
                  Acesso pais
                  <span className="form-check-sign">
                    <span className="check"></span>
                  </span>
                </Label>
              </FormGroup>
              <label></label>
              <UncontrolledTooltip placement="bottom" target="acessoPais" delay={0}>
                Habilita o login dos pais
              </UncontrolledTooltip>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup check>
                <Label id="turmaVirtual" className="form-check-label">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    checked={this.state.turmaVirtual}
                    onChange={(e) => this.setState({
                      turmaVirtual: e.target.checked,
                    })} />
                  Turma virtual
                  <span className="form-check-sign">
                    <span className="check"></span>
                  </span>
                </Label>
              </FormGroup>
              <label></label>
              <UncontrolledTooltip placement="bottom" target="turmaVirtual" delay={0}>
                Habilita o acesso às turmas virtuais pelos professores
              </UncontrolledTooltip>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup check>
                <Label id="alunosPorEscola" className="form-check-label">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    checked={this.state.alunosPorEscola}
                    onChange={(e) => this.setState({
                      alunosPorEscola: e.target.checked,
                    })} />
                  Alunos por escola
                  <span className="form-check-sign">
                    <span className="check"></span>
                  </span>
                </Label>
              </FormGroup>
              <label></label>
              <UncontrolledTooltip placement="bottom" target="alunosPorEscola" delay={0}>
                Habilita o agrupamento de alunos em escolas e habilita transferência de alunos entre escolas<br/><br/>Solicitado por São josé de Mipibu
              </UncontrolledTooltip>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup check>
                <Label id="registroAulas" className="form-check-label">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    checked={this.state.registroAulas}
                    onChange={(e) => this.setState({
                      registroAulas: e.target.checked,
                    })} />
                  Registro de aulas
                  <span className="form-check-sign">
                    <span className="check"></span>
                  </span>
                </Label>
              </FormGroup>
              <label></label>
              <UncontrolledTooltip placement="bottom" target="registroAulas" delay={0}>
                Habilita o cadastro de registro de aulas<br/><br/>Solicitado por Patos
              </UncontrolledTooltip>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup check>
                <Label id="habilidadesRegistroAula" className="form-check-label">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    checked={this.state.habilidadesRegistroAula}
                    onChange={(e) => this.setState({
                      habilidadesRegistroAula: e.target.checked,
                    })} />
                  Habilidades em registro de aula
                  <span className="form-check-sign">
                    <span className="check"></span>
                  </span>
                </Label>
              </FormGroup>
              <label></label>
              <UncontrolledTooltip placement="bottom" target="habilidadesRegistroAula" delay={0}>
                Habilita a seleção de objetivos de conhecimento/habilidades nos registros de aulas<br/><br/>Solicitado por Borborema
              </UncontrolledTooltip>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup check>
                <Label id="registroAulasPeriodo" className="form-check-label">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    checked={this.state.registroAulasPeriodo}
                    onChange={(e) => this.setState({
                      registroAulasPeriodo: e.target.checked,
                    })} />
                  Registro de aulas por período
                  <span className="form-check-sign">
                    <span className="check"></span>
                  </span>
                </Label>
              </FormGroup>
              <label></label>
              <UncontrolledTooltip placement="bottom" target="registroAulasPeriodo" delay={0}>
                Habilita registros de aula por período<br/><br/>Solicitado por Equador
              </UncontrolledTooltip>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup check>
                <Label id="recuperacaoExercicios" className="form-check-label">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    checked={this.state.recuperacaoExercicios}
                    onChange={(e) => this.setState({
                      recuperacaoExercicios: e.target.checked,
                    })} />
                  Recuperação exercícios
                  <span className="form-check-sign">
                    <span className="check"></span>
                  </span>
                </Label>
              </FormGroup>
              <label></label>
              <UncontrolledTooltip placement="bottom" target="recuperacaoExercicios" delay={0}>
              Habilita recuperação por exercícios<br/><br/>Solicitado por Patos
              </UncontrolledTooltip>
            </Col>
          </Row>
        </Col>
        <Col md="3">
          <Row>
            <Col>
              <FormGroup check>
                <Label id="somatorioExercicios" className="form-check-label">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    checked={this.state.somatorioExercicios}
                    onChange={(e) => this.setState({
                      somatorioExercicios: e.target.checked,
                    })} />
                  Somatório de exercícios
                  <span className="form-check-sign">
                    <span className="check"></span>
                  </span>
                </Label>
              </FormGroup>
              <label></label>
              <UncontrolledTooltip placement="bottom" target="somatorioExercicios" delay={0}>
                Habilita registros de aula por período<br/><br/>Solicitado por Equador
              </UncontrolledTooltip>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup check>
                <Label id="educaCenso" className="form-check-label">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    checked={this.state.educaCenso}
                    onChange={(e) => this.setState({
                      educaCenso: e.target.checked,
                    })} />
                  Educacenso
                  <span className="form-check-sign">
                    <span className="check"></span>
                  </span>
                </Label>
              </FormGroup>
              <label></label>
              <UncontrolledTooltip placement="bottom" target="educaCenso" delay={0}>
                Habilita exportação de dados para o Educacenso<br/><br/>Em desenvolvimento
              </UncontrolledTooltip>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup check>
                <Label id="competenciasMunicipais" className="form-check-label">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    checked={this.state.competenciasMunicipais}
                    onChange={(e) => this.setState({
                      competenciasMunicipais: e.target.checked,
                    })} />
                  Competências municipais
                  <span className="form-check-sign">
                    <span className="check"></span>
                  </span>
                </Label>
              </FormGroup>
              <label></label>
              <UncontrolledTooltip placement="bottom" target="competenciasMunicipais" delay={0}>
                Habilita competências exclusivas municipais, diferentes das competências da BNCC<br/><br/>Solicitado por Tenente Laurentino
              </UncontrolledTooltip>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup check>
                <Label id="matriculasOnline" className="form-check-label">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    checked={this.state.matriculasOnline}
                    onChange={(e) => this.setState({
                      matriculasOnline: e.target.checked,
                    })} />
                  Matrículas Online
                  <span className="form-check-sign">
                    <span className="check"></span>
                  </span>
                </Label>
              </FormGroup>
              <label></label>
              <UncontrolledTooltip placement="bottom" target="matriculasOnline" delay={0}>
                Habilita solicitação de matrículas online<br/><br/>Solicitado por Patos
              </UncontrolledTooltip>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup check>
                <Label id="bnccNacional" className="form-check-label">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    checked={this.state.bnccNacional}
                    onChange={(e) => this.setState({
                      bnccNacional: e.target.checked,
                    })} />
                  BNCC Nacional
                  <span className="form-check-sign">
                    <span className="check"></span>
                  </span>
                </Label>
              </FormGroup>
              <label></label>
              <UncontrolledTooltip placement="bottom" target="bnccNacional" delay={0}>
                Habilita BNCC nacional<br/><br/>Solicitado por São José de Mipibu
              </UncontrolledTooltip>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup check>
                <Label id="frequenciaFixa" className="form-check-label">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    checked={this.state.frequenciaFixa}
                    onChange={(e) => this.setState({
                      frequenciaFixa: e.target.checked,
                    })} />
                  Frequência Fixa
                  <span className="form-check-sign">
                    <span className="check"></span>
                  </span>
                </Label>
              </FormGroup>
              <label></label>
              <UncontrolledTooltip placement="bottom" target="frequenciaFixa" delay={0}>
                Habilita frequência fixa<br/><br/>Solicitado por Patos
              </UncontrolledTooltip>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup check>
                <Label id="frequenciaMensal" className="form-check-label">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    checked={this.state.frequenciaMensal}
                    onChange={(e) => this.setState({
                      frequenciaMensal: e.target.checked,
                    })} />
                  Frequência Mensal
                  <span className="form-check-sign">
                    <span className="check"></span>
                  </span>
                </Label>
              </FormGroup>
              <label></label>
              <UncontrolledTooltip placement="bottom" target="frequenciaMensal" delay={0}>
                Habilita lançamento de frequência por planilha mensal<br/><br/>Solicitado por Japi
              </UncontrolledTooltip>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup check>
                <Label id="limitesBimestrais" className="form-check-label">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    checked={this.state.limitesBimestrais}
                    onChange={(e) => this.setState({
                      limitesBimestrais: e.target.checked,
                    })} />
                  Limites Bimestrais
                  <span className="form-check-sign">
                    <span className="check"></span>
                  </span>
                </Label>
              </FormGroup>
              <label></label>
              <UncontrolledTooltip placement="bottom" target="limitesBimestrais" delay={0}>
                Habilita bloqueio de lançamento nos diários por limites bimestrais<br/><br/>Solicitado por São José de Mipibu
              </UncontrolledTooltip>
            </Col>
          </Row>
        </Col>
        <Col md="3">
          <Row>
            <Col>
              <FormGroup check>
                <Label id="avaliacaoEspecial" className="form-check-label">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    checked={this.state.avaliacaoEspecial}
                    onChange={(e) => this.setState({
                      avaliacaoEspecial: e.target.checked,
                    })} />
                  Plano Educacional Individualizado
                  <span className="form-check-sign">
                    <span className="check"></span>
                  </span>
                </Label>
              </FormGroup>
              <label></label>
              <UncontrolledTooltip placement="bottom" target="avaliacaoEspecial" delay={0}>
                Habilita plano educacional individualizado<br/><br/>Solicitado por João Câmara
              </UncontrolledTooltip>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup check>
                <Label id="importacaoAlunos" className="form-check-label">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    checked={this.state.importacaoAlunos}
                    onChange={(e) => this.setState({
                      importacaoAlunos: e.target.checked,
                    })} />
                  Importação de alunos
                  <span className="form-check-sign">
                    <span className="check"></span>
                  </span>
                </Label>
              </FormGroup>
              <label></label>
              <UncontrolledTooltip placement="bottom" target="importacaoAlunos" delay={0}>
                Habilita importação de alunos por meio de planilha<br/><br/>Solicitado por Equador
              </UncontrolledTooltip>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup check>
                <Label id="avaliacaoConceitual" className="form-check-label">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    checked={this.state.avaliacaoConceitual}
                    onChange={(e) => this.setState({
                      avaliacaoConceitual: e.target.checked,
                    })} />
                  Avaliação conceitual
                  <span className="form-check-sign">
                    <span className="check"></span>
                  </span>
                </Label>
              </FormGroup>
              <label></label>
              <UncontrolledTooltip placement="bottom" target="avaliacaoConceitual" delay={0}>
                Habilita avaliação conceitual<br/><br/>Solicitado por Equador
              </UncontrolledTooltip>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup check>
                <Label id="competenciasAluno" className="form-check-label">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    checked={this.state.competenciasAluno}
                    onChange={(e) => this.setState({
                      competenciasAluno: e.target.checked,
                    })} />
                  Competências por aluno
                  <span className="form-check-sign">
                    <span className="check"></span>
                  </span>
                </Label>
              </FormGroup>
              <label></label>
              <UncontrolledTooltip placement="bottom" target="competenciasAluno" delay={0}>
                Habilita avaliação de competências por aluno<br/><br/>Solicitado por Coronel Ezequiel
              </UncontrolledTooltip>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup check>
                <Label id="competenciasTurma" className="form-check-label">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    checked={this.state.competenciasTurma}
                    onChange={(e) => this.setState({
                      competenciasTurma: e.target.checked,
                    })} />
                  Competências por turma
                  <span className="form-check-sign">
                    <span className="check"></span>
                  </span>
                </Label>
              </FormGroup>
              <label></label>
              <UncontrolledTooltip placement="bottom" target="competenciasTurma" delay={0}>
                Habilita avaliação de competências por turma<br/><br/>Solicitado por Coronel Ezequiel
              </UncontrolledTooltip>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup check>
                <Label id="relatoriosCoordenadores" className="form-check-label">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    checked={this.state.relatoriosCoordenadores}
                    onChange={(e) => this.setState({
                      relatoriosCoordenadores: e.target.checked,
                    })} />
                  Relatórios coordenadores
                  <span className="form-check-sign">
                    <span className="check"></span>
                  </span>
                </Label>
              </FormGroup>
              <label></label>
              <UncontrolledTooltip placement="bottom" target="relatoriosCoordenadores" delay={0}>
                Habilita impressão de relatórios por coordenadores pedagógicos<br/><br/>Solicitado por João Câmara
              </UncontrolledTooltip>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup check>
                <Label id="bloquearAlteracaoDiarioProfessorAuxiliar" className="form-check-label">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    checked={this.state.bloquearAlteracaoDiarioProfessorAuxiliar}
                    onChange={(e) => this.setState({
                      bloquearAlteracaoDiarioProfessorAuxiliar: e.target.checked,
                    })} />
                  Bloquear alteração dos diários por professor auxilar
                  <span className="form-check-sign">
                    <span className="check"></span>
                  </span>
                </Label>
              </FormGroup>
              <label></label>
              <UncontrolledTooltip placement="bottom" target="bloquearAlteracaoDiarioProfessorAuxiliar" delay={0}>
                Bloqueia a alteração dos diários por professor auxiliar<br/><br/>Solicitado por Martins
              </UncontrolledTooltip>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup check>
                <Label id="controleEstoque" className="form-check-label">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    checked={this.state.controleEstoque}
                    onChange={(e) => this.setState({
                      controleEstoque: e.target.checked,
                    })} />
                  Controle de estoque
                  <span className="form-check-sign">
                    <span className="check"></span>
                  </span>
                </Label>
              </FormGroup>
              <label></label>
              <UncontrolledTooltip placement="bottom" target="controleEstoque" delay={0}>
                Ativa o controle de estoque<br/><br/>Solicitado por Taipu
              </UncontrolledTooltip>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md="12" className="text-right">
          <Button
            color="primary"
            onClick={() => this.salvarConfiguracaoRecursos()}>
            Salvar
          </Button>
        </Col>
      </Row>
    </> : <div align="center" style={{ margin: 50 }}>
      <Loader
        type="Oval"
        color="#053d7c"
        height="50"
        width="50" />
    </div>;
  }
}

class ConvitesEnte extends React.Component {
  constructor(props) {
    super(props);

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-convites-ente-admin`;
    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.emailRef = React.createRef();

    this.state = {
      dadosTabela: {
        header: [['Email', '30%'], ['Ente', '30%'], ['Data envio', '30%']],
        columnAlign: ['text-left', 'text-left', 'text-left'],
        keys: ['email', 'ente_str', 'data'],
        rows: [],
        total: undefined,
      },
    };

    this.entesService = new EntesService();
    this.convitesService = new ConvitesService();
  }

  async componentDidMount() {
    try {
      const ente = await this.entesService
        .carregarEnte(this.props.idEnte);

      this.setState({ ente });

      this.carregarConvitesGestoresPorEnte(this.paginaSelecionada, this.linhasPorPagina, '');
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  validarCampos() {
    let ret = true;
    if (!validator.isEmail(this.emailRef.current.value)) {
      this.setState({ emailState: 'has-danger' });
      ret = false;
    }
    return ret;
  }

  async enviarConvite() {
    if (!this.validarCampos()) return;

    this.props.callbackMessage({
      showLoaderModal: true,
      showAlert: false,
      loaderModalText: 'Enviando convite...',
    });

    const convite = {
      email: this.emailRef.current.value,
      papel: Papel.GESTOR.value,
      ente: this.state.ente,
    };

    try {
      await this.convitesService.cadastrarConviteGestor(convite);

      this.props.callbackMessage({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Convite enviado com sucesso. Um email com instruções foi enviado para o endereço informado.',
      });

      this.emailRef.current.value = '';

      this.carregarConvitesGestoresPorEnte(this.paginaSelecionada, this.linhasPorPagina, this.like);
    } catch (msg) {
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao enviar convite',
      });
    }
  }

  confirmarRemocaoConvite(convite) {
    this.setState({ conviteSelecionado: convite, showModal: true });
  }

  async removerConvite() {
    this.props.callbackMessage({
      showLoaderModal: true,
      showAlert: false,
      loaderModalText: 'Removendo convite...',
    });

    try {
      await this.convitesService.removerConvite(this.state.conviteSelecionado.id);

      if (this.state.dadosTabela.total % this.linhasPorPagina === 1
        && this.paginaSelecionada > 1) {
        this.paginaSelecionada -= 1;
      }

      this.carregarConvitesGestoresPorEnte(this.paginaSelecionada, this.linhasPorPagina, this.like);

      this.props.callbackMessage({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Convite removido com sucesso',
      });
    } catch (e) {
      this.props.callbackMessage({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao remover convite',
      });
    }
  }

  async carregarConvitesGestoresPorEnte(pagina, linhasPorPagina, like) {
    try {
      const data = await this.convitesService
        .carregarConvitesGestoresPorEnte(this.state.ente.id, pagina, linhasPorPagina, like);

      data.convites.forEach((convite) => {
        Object.assign(convite, { ente_str: `${convite.ente.nome}` });
      });

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.convites, total: data.total }),
      });
    } catch (e) {
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar convites cadastrados',
      });
    }
  }

  render() {
    if (this.state.erro) return <div align="center" style={{ margin: 50 }}>
      Erro ao buscar informações de convites
    </div>

    return this.state.ente ? <>
      <Row>
        <Col md="5">
          <FormGroup className={`has-label ${this.state.emailState}`}>
            <label>Email *</label>
            <Input
              innerRef={this.emailRef}
              onChange={() => this.setState({ emailState: '' })}
              type="text"
              maxLength='100'
              disabled={this.disableFields}
            />
            {this.state.emailState === 'has-danger' && (
              <label className="error">
                Informe um email válido.
              </label>
            )}
          </FormGroup>
        </Col>
        <Col>
          <Button
            style={{ marginTop: '26px' }}
            type="button"
            color="primary"
            disabled={this.state.buttonDisabled}
            onClick={() => this.enviarConvite()}>
            Enviar
          </Button>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <AdaptiveTable
            selectedPage={this.paginaSelecionada}
            rowsPerPage={this.linhasPorPagina}
            removeButtonCallback={(convite) => this
              .confirmarRemocaoConvite(convite)}
            rowsPerPageCallback={(info) => {
              this.paginaSelecionada = 1;
              sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
              this.linhasPorPagina = info.rowsPerPage;
              sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
              this.carregarConvitesGestoresPorEnte(this.paginaSelecionada, info.rowsPerPage, this.like);
            }}
            likeCallback={(text) => {
              this.like = text;
              this.paginaSelecionada = 1;
              sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
              this.carregarConvitesGestoresPorEnte(this.paginaSelecionada, this.linhasPorPagina, text);
            }}
            paginatorCallback={(page) => {
              this.paginaSelecionada = page;
              sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
              this.carregarConvitesGestoresPorEnte(this.paginaSelecionada, this.linhasPorPagina, this.like);
            }}
            data={this.state.dadosTabela}
            disableEditAction
            disablePrintAction />
        </Col>
      </Row>
      <Row>
        <Col className="text-left">
          <Button color="primary" disabled={this.state.buttonDisabled} onClick={() => this.props.history.goBack()} >
            Voltar
          </Button>
        </Col>
      </Row>
      <ConfirmModal
        isOpen={this.state.showModal}
        callback={(confirm) => {
          this.setState({ showModal: false });
          if (confirm) {
            this.removerConvite();
          }
        }}
        text='Confirme exclusão do convite' />
    </> : <div align="center" style={{ margin: 50 }}>
      <Loader
        type="Oval"
        color="#053d7c"
        height="50"
        width="50" />
    </div>;
  }

}

class ChamadosEnte extends React.Component {
  constructor(props) {
    super(props);

    this.idPagina3 = `${this.props.dadosUsuario.idVinculo}-chamados-ente-admin`;
    this.paginaSelecionada3 = sessionStorage.getItem(`${this.idPagina3}-pg`) || 1;
    this.linhasPorPagina3 = sessionStorage.getItem(`${this.idPagina3}-ln`) || 5;
    this.like3 = '';

    this.state = {
      dadosTabela2: {
        header: [['Referência', '10%'], ['Código PagSeguro', '30%'], ['Vencimento', '5%'], ['Plano', '10%'], ['Valor', '5%'], ['Situação', '10%']],
        columnAlign: ['text-center', 'text-center', 'text-center', 'text-center', 'text-center', 'text-center', 'text-center'],
        keys: ['referencia', 'codigoPagSeguro', 'dataVencimento', 'plano_str', 'preco', 'situacao_str'],
        rows: [],
        total: undefined,
      },
    }
  }

  async componentDidMount() {
    try {

    } catch (e) {
      this.setState({ erro: true });
    }
  }

  render() {
    if (this.state.erro) return <div align="center" style={{ margin: 50 }}>
      Erro ao buscar informações de convites
    </div>

    return <Tabs>
      <TabList>
        <Tab>Abertos</Tab>
        <Tab>Em andamento</Tab>
        <Tab>Finalizados</Tab>
      </TabList>
      <TabPanel>
        <ListagemChamados
          {...this.props}
          idEnte={this.props.idEnte}
          situacao={SituacaoChamado.ABERTO.value}
          callbackMessage={this.props.callbackMessage}
        />
      </TabPanel>
      <TabPanel>
        <ListagemChamados
          {...this.props}
          idEnte={this.props.idEnte}
          situacao={SituacaoChamado.EM_ANDAMENTO.value}
          callbackMessage={this.props.callbackMessage}
        />
      </TabPanel>
      <TabPanel>
        <ListagemChamados
          {...this.props}
          idEnte={this.props.idEnte}
          situacao={SituacaoChamado.FINALIZADO.value}
          callbackMessage={this.props.callbackMessage}
        />
      </TabPanel>
    </Tabs>;
  }
}

class MChatEnte extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}

    this.mChatsService = new MChatsService();
  }

  async componentDidMount() {
    try {
      await this.carregarMChats();
    } catch (e) {
      this.setState({ erro: true })
    }
  }

  async carregarMChats() {
    const mchats = await this.mChatsService.carregarMChatsAdmin(this.props.idEnte);

    this.setState({ mchats });
  }

  removerMChat(mchat) {
    const mchats = this.state.mchats.filter((mchat2) => mchat !== mchat2);
    this.setState({ mchats });
  }

  preencherComMChatsPadrao() {
    const mchats = getListaMChatPadrao();
    this.setState({ mchats });
  }

  validarCampos() {
    let ret = true;

    this.state.mchats.forEach((mchat) => {
      if (!mchat.descricao) {
        Object.assign(mchat, { descricaoState: 'has-danger' });
        ret = false;
      }
    });

    if (!ret) this.forceUpdate();

    return ret;
  }

  async salvarMChats() {
    if (!this.validarCampos()) return;

    this.props.callbackMessage({
      showLoaderModal: true,
      showAlert: false,
      loaderModalText: 'Salvando M-Chats...',
    });

    try {

      await this.mChatsService.salvarMChats(this.props.idEnte, this.state.mchats);

      this.props.callbackMessage({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'M-Chats salvos com sucesso.',
      });

      await this.carregarMChats();
    } catch (msg) {
      this.props.callbackMessage({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao salvar M-Chats.',
      });
    }
  }

  render() {
    if (this.state.erro) return <div align="center" style={{ margin: 50 }}>
      Erro ao buscar informações de M-Chats
    </div>

    return this.state.mchats ? <>
      <Row>
        <Col md="6">
          <Button
            color="primary"
            onClick={() => {
              this.state.mchats.push({
                descricao: '',
                exemplo: '',
                ordem: this.state.mchats.length,
              });
              this.forceUpdate();
            }}>
            Adicionar M-Chat
          </Button>
        </Col>
        <Col md="6" className="text-right">
          <Button
            color="default"
            onClick={() => this.preencherComMChatsPadrao()}>
            Adicionar M-Chats Padrão
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <PerfectScrollbar className="sisa-perfect-scrollbar">
            <Table>
              <thead className="text-primary">
                <tr>
                  <th style={{ width: '45%' }}>Descrição</th>
                  <th style={{ width: '45%' }}>Exemplo</th>
                  <th className="text-left" style={{ paddingLeft: 20, width: '10%' }}>Ação</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.mchats.map((mchat, index) => (
                    <tr key={index}>
                      <td>
                        <FormGroup className={`has-label ${mchat.descricaoState}`}>
                          <TextareaAutosize
                            className={`autoheight-curriculo ${mchat.descricaoState}`}
                            value={mchat.descricao}
                            onChange={(event) => {
                              Object.assign(mchat,
                                {
                                  descricao: event.target.value,
                                  descricaoState: 'primary',
                                });
                              this.forceUpdate();
                            }} />
                          {mchat.descricaoState === 'has-danger' ? (
                            <label className="error">
                              Informe a descrição do MChat.
                            </label>
                          ) : null}
                        </FormGroup>
                      </td>
                      <td>
                        <FormGroup className={`has-label ${mchat.exemploState}`}>
                          <TextareaAutosize
                            className={`autoheight-curriculo ${mchat.exemploState}`}
                            value={mchat.exemplo}
                            onChange={(event) => {
                              Object.assign(mchat,
                                {
                                  exemplo: event.target.value,
                                  exemploState: 'primary',
                                });
                              this.forceUpdate();
                            }} />
                          {mchat.exemploState === 'has-danger' ? (
                            <label className="error">
                              Informe um exemplo.
                            </label>
                          ) : null}
                        </FormGroup>
                      </td>
                      <td className="text-left" style={{ verticalAlign: 'top', paddingTop: '17px', paddingLeft: 20 }}>
                        <Button
                          id={`btnRemoverTesteMChat${index}`}
                          className="btn-icon"
                          color="danger"
                          size="sm"
                          onClick={() => this.removerMChat(mchat)}
                          type="button">
                          <i className="fa fa-times" />
                        </Button>
                        <UncontrolledTooltip placement="bottom" target={`btnRemoverTesteMChat${index}`} delay={0}>
                          Remover MChat
                        </UncontrolledTooltip>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </PerfectScrollbar>
        </Col>
      </Row>
      <Row>
        <Col md="6" className="text-left">
          <Button
            color="primary"
            onClick={() => this.props.history.goBack()} >
            Voltar
          </Button>
        </Col>
        <Col md="6" className="text-right">
          <Button color="primary"
            onClick={() => this.salvarMChats()} >
            Salvar MChats
          </Button>
        </Col>
      </Row>
    </> : <div align="center" style={{ margin: 50 }}>
      <Loader
        type="Oval"
        color="#053d7c"
        height="50"
        width="50" />
    </div>
  }
}

class Ente extends React.Component {
  constructor(props) {
    super(props);

    this.loginService = new LoginService();
    this.entesService = new EntesService();

    this.state = {
      showModal: false,
      showAlert: false,
    };
  }

  async componentDidMount() {
    try {
      const ente = await this.entesService
        .carregarEnte(this.props.match.params.idEnte);

      this.setState({
        ente,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  conteudoPagina() {
    return !this.state.ente
      ? <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      </Card> : <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to="/admin/entes">Entes</Link></BreadcrumbItem>
            <BreadcrumbItem active>{this.state.ente.nome}/{this.state.ente.estado.uf}</BreadcrumbItem>
          </Breadcrumb>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">{this.state.ente.nome}/{this.state.ente.estado.uf}</CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <Tabs>
                    <TabList>
                      <Tab>Limites</Tab>
                      <Tab>Recursos</Tab>
                      <Tab>Convites</Tab>
                      <Tab>Chamados</Tab>
                      <Tab>M-Chat</Tab>
                    </TabList>
                    <TabPanel>
                      <LimitesEnte
                        idEnte={this.props.match.params.idEnte}
                        callbackMessage={(msg) => this.setState(msg)} />
                    </TabPanel>
                    <TabPanel>
                      <RecursosEnte
                        idEnte={this.props.match.params.idEnte}
                        callbackMessage={(msg) => this.setState(msg)} />
                    </TabPanel>
                    <TabPanel>
                      <ConvitesEnte
                        idEnte={this.props.match.params.idEnte}
                        dadosUsuario={this.props.dadosUsuario}
                        callbackMessage={(msg) => this.setState(msg)} />
                    </TabPanel>
                    <TabPanel>
                      <ChamadosEnte
                        {...this.props}
                        idEnte={this.props.match.params.idEnte}
                        dadosUsuario={this.props.dadosUsuario}
                        callbackMessage={(msg) => this.setState(msg)} />
                    </TabPanel>
                    <TabPanel>
                      <MChatEnte
                        idEnte={this.props.match.params.idEnte}
                        callbackMessage={(msg) => this.setState(msg)} />
                    </TabPanel>
                  </Tabs>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(Ente);
